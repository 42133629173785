@import "../_variables.scss";

.navContainer {
  padding: 15px;
  @media only screen and (max-width: 1280px) {
    padding: 3px 3px 5px;
    margin-left: auto;
  }
  @media only screen and (max-width: 450px) {
    min-height: 55px;
  }
}

.pageNav {
  display: flex;
  align-items: center;
  height: 50px;
  @media only screen and (max-width: 1280px) {
    margin: 5px;
    height: 35px;
  }
}

.navItem {
  margin-right: 20px;
  color: #000;
  font-size: 16px;
  text-decoration: none;
  font-family: $main-font;
}

.pageToolbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  margin-right: 25px;
  @media only screen and (max-width: 1280px) {
    margin-right: unset;
    height: 18px;
  }
  button {
    cursor: pointer;
    border: none;
    background-color: #fff;
    margin: 5px;
    margin-top: 10px;
  }
  .searchDesktop {
    display: flex;
    border: 1px solid $secondary;
    align-items: center;
    @media only screen and (max-width: 1280px) {
      position: absolute;
      top: 65px;
      left: calc(50% - 250px/2);
      z-index: 9999;
      }
    input {
        width: 210px;
        height: 38px;
        padding-left: 10px;
        background-color: #fff;
        color: $main-text;
        border: none;
        font-size: 14px;
        font-weight: 400;
        font-family: $main-font;
        outline: none;
    }
  }
  .mobileSearchButton {
     @media only screen and (min-width: 1280px) {
      display: none;
    }
  }
}


.searchMobileContainer {
  height: 60px;
  background-color: #fff;
  width: 100%;
  position: absolute;
  left: 0;
  top: 55px;
  border-bottom: 1px solid $secondary;
}
