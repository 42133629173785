@import '../variables.scss';

.sideBarContainer {
  position: absolute;
  top: 115px;
  left: 0px;
  @media only screen and (max-width: 1280px) {
    position: unset;
    margin-top: 12px;
    padding-top: 3px;
    min-height: 33px;
   }
}

.menuButton {
  display: none;
  @media only screen and (max-width: 1280px) {
    display: block;
    background: #fff;
    border: none;
    padding-right: 2px;
   }
}

.menu {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  width: 200px;
  margin: 15px 20px -21px 0px;
  font-family: $main-font;
  a {
    width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $nav-text;
    line-height: 23px;
    border-bottom: 1px solid $secondary;
    padding: 15px 20px 15px 10px;
    font-size: 20px;
  }

  a:hover {
    padding-left: 25px;
    transition:all .3s ease;
    background: $nav-text;
    color: #FFF;
    svg{
      path {
        stroke: #FFF;
      }
    }
  }
  .categoryActive {
    background-color: $secondary;
    :hover {
      background-color: unset;
    }
  }

  .navItem {
    display: flex;
    align-items: center;
    svg {
      position: absolute;
      left: 174px;
    }
  }

  .navItem:hover {
    svg{
      path {
        stroke: #FFF;
      }
    }
  }

  @media only screen and (max-width: 1280px) {
   display: none;
  }
  @media only screen and (max-width: 1280px) {
      height: 94%;
      top: 39px;
      position: fixed;
      display: flex;
      overflow: scroll;
      background: #fff;
      width: 100%;
    a {
      height: 50px;
      font-size: 17px;
      width: 100%;
    }
   }
  
}

.menuMobile {
 display: none;
  
}

.menu::-webkit-scrollbar {

  @media only screen and (max-width: 1280px) {
    display: none;
   }
}

.categoryActive {
  @media only screen and (max-width: 1280px) {
    background-color: $secondary;
   }

}


