@import '../variables.scss';

.footer {
  margin: 20px auto auto auto;
  background-color: #FFF;
  text-align: left;
  position: relative;
  width: 100%;
  padding: 10px calc(48% - 640px) 40px calc(53% - 640px);
  border-top: 1px solid $secondary;

  h2 {
    font-family: $main-font;
    color: $nav-text;
    font-weight: 400;
    line-height: 30px;
    font-size: 20px;
  }
  p {
    font-family: $main-font;
    color: $main-text;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 25px;
    font-size: 16px;
  }
  hr {
    display: none;
  }
  @media only screen and (max-width: 1280px) {
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: unset;
    hr {
      display: block;
      border: none;
      border-bottom: 1px solid #fff;
      width: 90%;
    }
  } 
  
}

.footerCol {
  width: 60%;
  margin: 0 auto;
    p {
      a{
        text-decoration: underline; 
      }

    }

  @media only screen and (max-width: 1280px) {
    width: 95%;
    padding: unset;
    margin-bottom: 80px;
  }
}

.languageContainerMobile {
  display: none;
  @media only screen and (max-width: 450px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    
    div {
      margin-bottom: 10px;
    }
  }
}
