@import '../variables.scss';

.listContainer {
  display: flex;
  margin-top: 35px;
  padding-left: 220px;
  @media only screen and (max-width: 1280px) { 
    padding-left: unset;
  }
}

.listContainerNoMenu {
  display: flex;
  margin: 35px 150px 0px 150px;
  @media only screen and (max-width: 1280px) { 
    margin: 35px 0px 0px 0px;
  }
}

.errorPage {
  min-height: 1668px;
  @media only screen and (max-width: 1280px) {
    min-height: 400px;
  }
 }
.stickyBottomButton {
  display: none;
  @media only screen and (max-width: 450px) {
    display: block;
    padding: 8px 5px;
    line-height: 34px;
    background-color: $primary;
    color: #dfefff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1;
    text-align: center;
    span {
      border: 1px solid $secondary;
      display: block;
    }
    .stickyBottomText{
      padding: 12px 0;
    }
  }
}