@import '../variables.scss';

.languageContainer select {
  margin: 7px 0 0px 0;
  width: 140px;
  height: 31px;
  padding: 2px 2px 2px 2px;
  font-family: $main-text;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid $secondary;
  background-color: #fff;
  @media only screen and (max-width: 1280px) {
    margin: unset;
    width: 180px;
  }
}
