@import '../variables.scss';

.footer {
  display: flex;
  color: #000;
  justify-content: space-between;
  border-top: 1px solid $secondary;
  padding-top: 50px;
  text-decoration: unset;
  @media only screen and (max-width: 1200px) {
    justify-content: space-evenly;
  }
  @media only screen and (max-width: 450px) {
    flex-direction: column;
  }
  span {
    margin: 3px 0 3px 0;
    font-size: 15px;
    line-height: 24px;
    color: #424242;
    cursor: pointer;
  }
}

.col1 {
  display: flex;
  flex-direction: column;
  font-family: $main-font;
  font-weight: 400;
  div {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
  }
  @media only screen and (max-width: 450px) {
    position: absolute;
    left: 53%;
    bottom: 320px;
    height: 130px;
    div {
      margin-left: 20px;
    }
    a {
      text-align: left;
      padding-left: 30px;
      line-height: 30px;
    }
  }
  @media only screen and (max-width: 375px) {
    position: absolute;
    left: 48%;
    bottom: 320px;
  }
}

.col2 {
  display: flex;
  flex-direction: column;
  div {
    font-family: $main-font;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .languageDropdown {
    align-self: center;
    @media only screen and (max-width: 450px) {
      text-align: center;
    }
  }
  @media only screen and (max-width: 450px) {
    margin: 10px 30px 100px 10%;
  }
  @media only screen and (max-width: 320px) {
    margin-left: 5%;
  }
}

.col3 {
  display: flex;
  flex-direction: column;
  font-family: $main-font;
  font-weight: 400;
  @media only screen and (max-width: 450px) {
    margin: 0px 20px;
  }
  .logos {
    display: flex;
    margin-bottom: unset;
    justify-content: space-evenly;
    width: 100%;
    svg {
      path:hover {
        fill: $primary
        ;
      }
    }
    @media only screen and (max-width: 450px) {
      justify-content: center; 
      border-top: 1px solid $secondary;
      padding-top: 15px;
    }
  }
  div {
    font-size: 20px;
    line-height: 30px;
    align-self: center;
    margin-bottom: 10px;
  }
}
