@import '../variables.scss';

.errorContainer{

    margin: auto;
    padding: 10px;

    .errorOuter{
        display: flex;
        background-color: $nav-background;
        border: 1px solid $secondary;
        margin-top: 40px;
        @media only screen and (max-width: 768px) {
            padding: 20px;
        }
    }
    .errorInner{
        margin: 60px;
        text-align: center;
        border: 1px solid $secondary;
        padding: 40px;
        .primaryButton{
            margin: 40px auto;
            display: inline-block;
            min-height:54px;
            font-size: 20px;
            padding: 15px 5px;
            span{
                padding: 10px 24px ;
            }
            svg{
                margin-right:15px ;
            }
        }
        @media only screen and (max-width: 768px) {
            border: none;
            margin:unset;
            padding: unset;
        }

    }
    .errorTitle{
        font-family: $h1-font;
        color: $primary;
        font-size: 16px;
        font-weight: 400;
        line-height: 40px;
        text-align: center;

    }
    .errorText{
        text-align: center;
        font-family: $main-font;
        line-height: 24px;
    }



}