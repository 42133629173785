@import '../variables.scss';

.header {
  display: flex;
  width: 100%;
  background-color: #FFF;
  z-index: 9999;
  justify-content: unset;
  position: fixed;
  top: 0;
  border-bottom: 1px solid $secondary;
  @media only screen and (max-width: 450px) {
    min-height: 56px;
  }
  .logoContainer {
    height: 52px;
    width: 180px;
    cursor: pointer;
    padding-left: 5px;
    @media only screen and (max-width: 450px) {
      min-height: 55px;
    }
    @media only screen and (max-width: 375px) {
      width: 165px;
    }
    @media only screen and (max-width: 350px) {
      width: 144px;
    }
    @media only screen and (max-width: 340px) {
      width: 135px;
    }
    @media only screen and (max-width: 320px) {
      width: 110px;
    }
  }
}

.mobileMenu {
  display: flex;
}

@media only screen and (min-width: 1280px) {
  .header {
    position: relative;
    height: 80px;
    padding-left: 20px;
    width: auto;
    background-color: #fafafa;
    z-index: 10;
    justify-content: space-between;
    .logoContainer {
      height: 80px;
      width: 250px;
    }
  }

  .mobileMenu {
    display: none;
  }
}
