// This file will contains variables used for colors and media query sizing accross the SCSS files

$primary: #0D381C;
$secondary: #C9BA7C;  
$logo-text: #000;
$nav-text: #222222;
$main-text: #424242;
$main-background: #F8F8F8;
$nav-background: #FFF;

$h1-font: 'La Belle Aurore', normal;
$main-font: 'Niramit', normal;