@import "../_variables.scss";

.cookieConsentBanner{
  position:fixed;
  bottom:0px;
  width:100%;
  color: #000;
  background: #FFF;
  padding: 15px;
  z-index: 9999;
  border-top: 1px solid $secondary;
  p{
    font-size: 16px;
    color: $main-text;
    line-height: 24px;
    font-family: $main-font;
    font-weight: 400;
  }
  a{
    text-decoration: underline;
  }
  .okButton{
    position:absolute;
    top: 20px;
    right: 21px;
    border-radius: 50%;
    border:2px solid $secondary;
    background: $primary;
    height: 45px;
    width: 45px;
  @media only screen and (max-width: 1280px) {
    position:absolute;
    top:-17px;
    right:31px;
    border-radius: 50%;
    background: $primary;
    padding:12px;
    a{
      color:white;
    }
   }
    a{
    text-decoration: none;
    position:absolute;
    top: 7px;
    right: 11px;
    color:white;
    line-height: 24px;
    font-family: $main-font;
    font-weight: 400;
    }
  }
  .okButton2{
    position:absolute;
    top: 13px;
    right: 14px;
    border-radius: 50%;
    border:2px solid white;
    background: $primary;
    height: 60px;
    width: 59px;
    @media only screen and (max-width: 1280px) {
      position:absolute;
      top:-24px;
      right:24px;
      border-radius: 50%;
      border:2px solid white;
      background: $primary;
      a{
        color:white;
      }
     }
  }
}

