@import '../variables.scss';

.primaryButton {
  padding: 9px 5px;
  line-height: 30px;
  background-color: $primary;
  color: #dfefff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  box-shadow: 0px 1px 3px 0px $nav-text;
  font-family: $main-font;
  cursor: pointer;
  span {
    border: 1px solid $secondary;
    padding: 8px 18px;
  }
}

.primaryButton:hover {
  background-color: #FFF;
  color: #000;
}

@media only screen and (max-width: 1280px) {
  .primaryButton {
    padding: 1px 3px;
    font-size: 14px;
    span {
      border: 1px solid $secondary;
      padding: 3px 10px;
    }
  }
}